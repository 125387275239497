@import url('https://fonts.googleapis.com/css?family=Open+Sans');

#root {
    padding: 0 20px;
    max-width: 768px;
    margin: 0 auto;
}

h1 {
    font-size: 5.1em;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

body {
    font-family: "Open Sans", "Arial", sans-serif;
    font-size: 11pt;
}
