@import url(https://fonts.googleapis.com/css?family=Open+Sans);
a {
    color: #036;
}

p {
    line-height: 22px;
}

#results {
    width: 100%;
    padding-top: 10px;
}

#results .result {
    background-color: rgb(249, 187, 223);
    padding: 10px;
}

#results form {
    margin-top: 8px;
}

div.url-list {

}
div.success {
  text-align: center;
}

#results .pure-form input.url {
    padding-top: 9px;
    padding-bottom: 9px;
    display: inline-block;
    width: 450px;
    font-size: 2.1em;
}

button.button-go {
    background-color: rgb(255, 102, 204);
}

form.url-harvester button[type=submit] {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 5px;
    font-size: 1.5em;
    height: 51px;
}

div.url-list {
  padding-top: 10px;
}

div.url-list table {
  width: 100%;
  table-layout: fixed;
}

div.url-list table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.url-list table th.long-url {
  width: 20em;
}

div.url-list table th.created {
  width: 10em;
}

div.url-list table th.visits,
div.url-list table td.visits {
  text-align: right;
  width: 3em;
}

div.url-list table tfoot {
  background-color: #e0e0e0;
}

div.url-list table tfoot div.pagination {
  text-align: right;
}

div.url-list table tfoot div.pagination button {
  margin-left: 2px;
}

div.url-list table tfoot div.pagination p.details {
  display: inline;
  padding-right: 5px;
}

#root {
    padding: 0 20px;
    max-width: 768px;
    margin: 0 auto;
}

h1 {
    font-size: 5.1em;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

body {
    font-family: "Open Sans", "Arial", sans-serif;
    font-size: 11pt;
}

