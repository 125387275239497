div.url-list {
  padding-top: 10px;
}

div.url-list table {
  width: 100%;
  table-layout: fixed;
}

div.url-list table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.url-list table th.long-url {
  width: 20em;
}

div.url-list table th.created {
  width: 10em;
}

div.url-list table th.visits,
div.url-list table td.visits {
  text-align: right;
  width: 3em;
}

div.url-list table tfoot {
  background-color: #e0e0e0;
}

div.url-list table tfoot div.pagination {
  text-align: right;
}

div.url-list table tfoot div.pagination button {
  margin-left: 2px;
}

div.url-list table tfoot div.pagination p.details {
  display: inline;
  padding-right: 5px;
}
